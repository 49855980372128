import { defineStore } from 'pinia';
import { usePoolsV1Store } from './usePoolsV1Store';
import { reactive, ref } from 'vue';
import { IPersistedPoolSolo } from '@/types/pools';
import { fetchSoloLiquidity } from '@/service/api';
import { useStore } from './useStore';
import { composeType } from '@/utils/contracts';
import { useAptosClient } from '@/composables/useAptosClient';
import { useNumberFormat } from '@/composables/useCurrencyFormat';
import { useTokensStore } from './useTokensStore';
import { getTokenPrice, getUSDEquivalent } from '@/composables/useCoinPrice';
import { MODULES_V1_ACCOUNT } from '@/constants';

const soloPoolsDatas = [
  {
    id: 'Liquidswap v1 #1 "USDT"-"APT"-"X20"',
    coinXVault:
      '0xb38fef9651d40106cbfc2e284b88f75e9c8f12e62f9bd1ef91c02e1cc64752c1',
    coinYVault:
      '0x2ebf858dc3dbd279612cba431cc6fd2fdc9395a65d94583ea8e9f4fad3d9c19d',
  },
];

const SOLO_DEPO_SOURCE =
  '0x2aa557d92dc24df8f2ac2e5226d964f479bb51ffba645c3f33efc75f16a1b353';

export const usePoolsSoloStore = defineStore('usePoolsSoloStore', () => {
  const { poolsMap: v1Pools } = usePoolsV1Store();
  const aptosClient = useAptosClient();
  const tokensStore = useTokensStore();
  const store = useStore();

  const soloPools = reactive<Array<IPersistedPoolSolo>>([]);
  const isLoading = ref(false);

  const initPools = async () => {
    try {
      isLoading.value = true;
      for (const soloPoolData of soloPoolsDatas) {
        const v1Pool = v1Pools[soloPoolData.id];
        if (!v1Pool) continue;

        if (soloPoolData.coinXVault) {
          const compiledSoloPool: IPersistedPoolSolo = {
            ...v1Pool,
            tvl: 0,
            reserveX: 0,
            reserveY: 0,
            depositToken: v1Pool.coinX,
            vault: soloPoolData.coinXVault,
          };

          const found = soloPools.find(
            (item) =>
              item.vault === soloPoolData.coinXVault &&
              item.depositToken === v1Pool.coinX,
          );

          if (found) continue;

          soloPools.push(compiledSoloPool);
        }
        if (soloPoolData.coinYVault) {
          const compiledSoloPool: IPersistedPoolSolo = {
            ...v1Pool,
            tvl: 0,
            reserveX: 0,
            reserveY: 0,
            depositToken: v1Pool.coinY,
            vault: soloPoolData.coinYVault,
          };

          const found = soloPools.find(
            (item) =>
              item.vault === soloPoolData.coinYVault &&
              item.depositToken === v1Pool.coinY,
          );

          if (found) continue;

          soloPools.push(compiledSoloPool);
        }
      }
    } catch (e) {
      console.error('usePoolsSoloStore: initPools', e);
    } finally {
      isLoading.value = false;
    }

    try {
      const promises = soloPools.map(updateUserAmount);
      await Promise.all(promises);
    } catch (e) {
      //
    }
    try {
      const promises = soloPools.map(fetchTVL);
      await Promise.all(promises);
    } catch (e) {
      //
    }
    try {
      const promises = soloPools.map(checkDepo);
      await Promise.all(promises);
    } catch (e) {
      //
    }
  };

  const fetchTVL = async (pool: IPersistedPoolSolo) => {
    const viewPayload = {
      function: composeType(
        SOLO_DEPO_SOURCE,
        'vault',
        'get_total_amounts_view',
      ),
      type_arguments: [
        pool.coinX,
        pool.coinY,
        composeType(MODULES_V1_ACCOUNT, 'bin_steps', `X${pool.binStep}`),
      ],
      arguments: [pool.vault],
    };
    const data = await aptosClient.AptosSDK.view(viewPayload);

    if (data.length !== 2) return;

    const coinXReserves = data[0];
    const coinYReserves = data[1];

    pool.reserveX = Number(coinXReserves);
    pool.reserveY = Number(coinYReserves);

    const tokenX = tokensStore.getToken(pool.coinX);
    const tokenY = tokensStore.getToken(pool.coinY);
    const symbolX = tokenX?.symbol;
    const symbolY = tokenY?.symbol;
    const decimalX = tokenX?.decimals ? tokenX.decimals : 0;
    const decimalY = tokenY?.decimals ? tokenY.decimals : 0;

    const reservesX = useNumberFormat(pool.reserveX, {
      decimals: decimalX,
      suffix: '',
    }).value.replaceAll(',', '');

    const reservesY = useNumberFormat(pool.reserveY, {
      decimals: decimalY,
      suffix: '',
    }).value.replaceAll(',', '');

    try {
      const currencyXPrice = await getTokenPrice(symbolX);
      const currencyYPrice = await getTokenPrice(symbolY);

      if (!currencyXPrice || !currencyYPrice) return;

      const usdX = getUSDEquivalent(+reservesX, currencyXPrice);
      const usdY = getUSDEquivalent(+reservesY, currencyYPrice);
      if (!usdX && !usdY) return;

      const totalUsd = +(usdX || 0) + +(usdY || 0);
      pool.tvl = totalUsd;
    } catch (error) {
      console.error('fetchTvl', error);
    }
  };

  const checkDepo = async (pool: IPersistedPoolSolo) => {
    const { lp, lpTotal, reserveX, reserveY } = pool;
    const ratio = lp && lpTotal ? lp / lpTotal : 0;

    const userX = (reserveX ?? 0) * ratio;
    const userY = (reserveY ?? 0) * ratio;

    pool.addedX = userX;
    pool.addedY = userY;
  };

  const updateUserAmount = async (pool: IPersistedPoolSolo) => {
    const address = store.account.value?.address;

    if (!address) return;

    const userBinsWithLiquidity = await fetchSoloLiquidity(
      address,
      // RESOURCES_V1_ACCOUNT,
      '0x3948d5ed5e4726d38b98c86d3819f6fdab1b7641f97b5b3fcd4984f388ab449a',
    );

    const liquidity = userBinsWithLiquidity.find(
      (item) => item.name.split('@')[1]?.slice(0, -1) === pool.vault,
    );

    pool.lp = liquidity ? liquidity.amount : 0;

    //fetch total lp
    try {
      const viewPayload = {
        function: composeType(SOLO_DEPO_SOURCE, 'vault', 'get_total_shares'),
        type_arguments: [
          pool.coinX,
          pool.coinY,
          composeType(MODULES_V1_ACCOUNT, 'bin_steps', `X${pool.binStep}`),
        ],
        arguments: [pool.vault],
      };
      const data = await aptosClient.AptosSDK.view(viewPayload);
      const totalShares = data[0];
      if (!totalShares) return;
      pool.lpTotal = Number(totalShares);
    } catch (e) {
      //
    }
  };

  return { initPools, soloPools, isLoading };
});
